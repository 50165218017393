export const ACCESSORIES_CATEGORIES = {
  sunglass: [
    "aviator",
    "wayfarer",
    "round",
    "cat eye",
    "butterfly",
    "full rim",
    "rimless",
    "half rim",
  ],
  shoes: ["sneaker", "boot", "loafer", "oxford", "derby", "mule", "wedge"],
  sandal: [
    "wedge",
    "sandal",
    "jutti",
    "slide",
    "heel",
    "platforms",
    "stilletos",
    "flats",
  ],
  clutch: [
    "creeper box",
    "capsule",
    "clasp",
    "threadwork",
    "embroidered",
    "banarasi",
    "set",
  ],
  potli: [
    "creeper",
    "embroidered",
    "scallop",
    "mughal",
    "fringe",
    "banarasi",
    "set",
  ],
  purse: ["shoulder", "crossbody", "satchel", "tote", "wallet", "set"],
  bag: [
    "bucket",
    "baguette",
    "sling",
    "tote",
    "hobo",
    "moon",
    "satchel",
    "mini",
    "box",
    "bagpack",
    "laptop",
  ],
  belt: [],
  button: [],
  cufflink: ["classic", "novelty", "engraved", "vintage", "sports", "wedding"],
  rakhi: ["bracelet", "thread", "floral", "cartoon", "organic"],
};
