export function snakeToTitleCase(snakeCase: string) {
  // Split the snake case string into words.
  const words = snakeCase.split("_");

  // Capitalize the first letter of each word.
  for (let i = 0; i < words.length; i++) {
    words[i] = words[i]!.charAt(0).toUpperCase() + words[i]!.slice(1);
  }

  // Return the title case string.
  return words.join(" ");
}

export function snakeToLowerCase(snakeCase: string) {
  // Split the snake case string into words.
  const words = snakeCase.split("_");

  // Capitalize the first letter of each word.
  for (let i = 0; i < words.length; i++) {
    words[i] =
      (i === 0
        ? words[i]!.charAt(0).toUpperCase()
        : words[i]!.charAt(0).toLowerCase()) + words[i]!.slice(1);
  }

  // Return the title case string.
  return words.join(" ");
}
