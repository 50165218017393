export const JEWELLERY_CATEGORIES = {
  ring: [
    "engagement",
    "wedding",
    "promise",
    "birthstone",
    "stackable",
    "signet",
    "eternity",
    "cocktail",
    "mood",
    "statement",
    "midi",
    "toe",
    "spinner",
    "claddagh",
    "cameo",
    "band",
    "solitaire",
    "vintage",
    "cluster",
    "halo",
  ],
  necklace: [
    "chain",
    "pendant",
    "choker",
    "locket",
    "collar",
    "y-necklace",
    "tassel",
    "multi-strand",
    "statement",
    "bib",
    "bolo",
    "station",
    "mangalsutra",
  ],
  bracelet: [
    "bangle",
    "cuff",
    "chain",
    "tennis",
    "link",
    "charm",
    "stretch",
    "beaded",
    "wrap",
    "toggle",
    "haathphool",
    "handcuff",
    "lace",
    "choori",
    "kada",
  ],
  earring: [
    "stud",
    "hoop",
    "drop",
    "dangle",
    "chandelier",
    "climber",
    "ear jacket",
    "ear cuff",
    "threader",
    "mismatched",
    "dangler",
    "ear huggies",
    "statement",
    "jhumka",
    "earring",
    "chaandbali",
    "chandbali",
    "saharra",
    "long earring",
    "tube hoop",
  ],
  anklet: [
    "chain",
    "beaded",
    "charm",
    "tassel",
    "adjustable",
    "pendant",
    "payal",
  ],
  brooche: ["vintage", "floral", "animal", "art deco", "geometric"],
  charm: [
    "animals",
    "flowers",
    "hearts",
    "sports",
    "travel",
    "religious",
    "letters",
    "numbers",
    "birthstones",
    "enamel",
    "clip-on",
  ],
  pendant: [
    "necklace",
    "charm",
    "locket",
    "birthstone",
    "religious",
    "heart",
    "animal",
    "flower",
    "geometric",
  ],
  pin: ["vintage", "floral", "animal", "art deco", "geometric"],
  "nose-pin": ["nath", "nosepin"],
  "belly rings": [],
  "nose rings": [],
  "eyebrow rings": [],
  "lip rings": [],
  "nipple rings": [],
  "septum rings": [],
  "cartilage rings": [],
  "toe rings": [],
  "ankle chains": [],
  "arm bands": [],
  "hand chains": [],
  headbands: [],
  "naval studs": [],
  "toe-ring": [
    "adjustable toe ring",
    "band toe ring",
    "gemstone toe ring",
    "hawaiian toe ring",
    "heart toe ring",
    "flower toe ring",
    "animal toe ring",
    "infinity toe ring",
    "modern toe ring",
  ],
  sheeshphool: [],
  maangtika: [],
  dholna: [],
  jhumar: [],
  "matha patti": [],
  passa: [],
  borla: [],
  tiara: [
    "bandeau",
    "circlet",
    "cotton headband",
    "diana's lover's knot tiara",
    "emerald tiara",
    "feather tiara",
    "halo tiara",
    "kokoshnik tiara",
    "lover's knot tiara",
    "meander tiara",
    "pearl tiara",
    "princess tiara",
    "russian tiara",
    "st. edward's crown",
    "vladimir tiara",
  ],
  chain: [
    "box",
    "curb",
    "hoop",
    "mariner",
    "oyster",
    "rolo",
    "snake",
    "tennis",
    "vintage",
  ],
  "tie-pin": [
    "classic",
    "dangle",
    "geometric",
    "initial",
    "lapel",
    "pendant",
    "retro",
    "statement",
    "vintage",
  ],
  set: [
    "necklace set",
    "earring set",
    "bangle set",
    "bracelet set",
    "anklet set",
    "ring set",
    "kundan",
    "meenakari",
    "tikka",
  ],
};
