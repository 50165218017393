export const JEWELLERY_CATEGORIES = {
  ring: ["band", "cuff", "engagement", "fashion", "signet", "wedding"],
  necklace: ["choker", "collar", "lariat", "locket", "pendant", "rope"],
  bracelet: ["beaded", "cuff"],
  earring: ["hoop", "huggie", "dangle", "threader", "cuff", "drop"],
  brooche: ["vintage", "floral", "animal", "art deco", "geometric"],
  charm: [
    "animals",
    "flowers",
    "hearts",
    "sports",
    "travel",
    "religious",
    "letters",
    "numbers",
    "birthstones",
    "enamel",
    "clip-on",
  ],
  pendant: [
    "necklace",
    "charm",
    "locket",
    "birthstone",
    "religious",
    "heart",
    "animal",
    "flower",
    "geometric",
  ],
  pin: ["vintage", "floral", "animal", "art deco", "geometric"],
  "nose-pin": ["nath", "nosepin"],
  "belly rings": [],
  "nose rings": [],
  "eyebrow rings": [],
  "lip rings": [],
  "septum rings": [],
  "cartilage rings": [],
  "toe rings": [],
  "ankle chains": [],
  "arm bands": [],
  "hand chains": [],
  headbands: [],
  chain: [
    "box",
    "curb",
    "hoop",
    "mariner",
    "oyster",
    "rolo",
    "snake",
    "tennis",
    "vintage",
  ],
  "tie-pin": [
    "classic",
    "dangle",
    "geometric",
    "initial",
    "lapel",
    "pendant",
    "retro",
    "statement",
    "vintage",
  ],
};
