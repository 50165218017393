export const CLOTHING_CATEGORIES = {
  saree: [
    "banarasi",
    "chanderi",
    "kanjivaram",
    "kanchipuram",
    "tussar",
    "paithani",
    "baluchari",
    "muga",
    "eri",
    "sonepuri",
    "bomkai",
    "mysore",
    "gadwal",
    "kondrad",
    "patola",
    "pochampali",
    "ikkat",
    "jamdani",
    "printed",
    "sambhalpuri",
    "tant",
    "khadi",
    "dhakai",
    "chikankari",
    "gadwal",
    "kota doria",
    "leheriya",
    "gota patti",
    "lehariya",
    "laheriya",
    "ruffle",
    "cocktail",
    "bandhani",
    "satin silk",
    "organza silk",
    "cotton silk",
    "sequin",
    "tie and dye",
    "tissue",
    "printed",
  ],
  poshak: [
    "chanderi",
    "zardosi",
    "gota patti",
    "sikhiya",
    "aari katori",
    "aari kardana",
    "lehariya",
    "banarasi",
    "aari",
    "dori",
  ],
  jeans: [
    "skinny",
    "bootcut",
    "straight",
    "flared",
    "boyfriend",
    "mom",
    "high-waist",
    "low-waist",
    "ripped",
    "distressed",
    "acid-washed",
    "wide-leg",
  ],
  kurti: [
    "anarkali",
    "straight",
    "a-line",
    "flared",
    "asymmetric",
    "jacket-style",
    "shirt-style",
    "high-low",
    "kaftan-style",
    "tunic-style",
    "printed",
    "embroidered",
    "solid-color",
    "chikankari",
    "block-printed",
    "mirror-work",
    "bandhani",
    "tie-dye",
    "phulkari",
    "lehariya",
    "shimmer",
    "collar",
  ],
  top: [
    "crop",
    "tank",
    "peplum",
    "wrap",
    "halter",
    "off-the-shoulder",
    "cold shoulder",
    "one-shoulder",
    "tube",
    "lace",
    "sequin",
    "embroidered",
    "printed",
    "solid-color",
    "sleeveless",
    "short-sleeve",
    "long-sleeve",
    "boat neck",
    "v-neck",
    "round neck",
    "collared",
    "button-up",
    "oversized",
    "fitted",
    "flowy",
    "sheer",
    "tie-up",
    "cowl",
    "luna",
  ],
  "t-shirt": [
    "polo",
    "round neck",
    "v neck",
    "crew neck",
    "scoop neck",
    "long sleeve",
    "graphic",
    "sports",
    "oversized",
  ],
  dress: [
    "maxi",
    "midi",
    "mini",
    "bodycon",
    "a-line",
    "wrap",
    "shift",
    "shirt",
    "tunic",
    "off-the-shoulder",
    "cold shoulder",
    "one-shoulder",
    "sleeveless",
    "short-sleeve",
    "long-sleeve",
    "strapless",
    "backless",
    "halter",
    "lace",
    "sequin",
    "embroidered",
    "printed",
    "solid-color",
    "pleated",
    "ruffle",
    "tiered",
    "asymmetric",
    "floral",
    "polka dot",
    "striped",
    "plaid",
    "finch",
    "frill",
    "flared",
    "bandhani",
    "lehariya",
    "blocked",
    "fishtail",
    "cowl",
    "gown",
    "haycinth",
    "crescent",
  ],
  "dress-material": [],
  lehenga: [
    "bridal",
    "ghagra choli",
    "chaniya choli",
    "a-line",
    "flared",
    "mermaid",
    "circular",
    "pleated",
    "printed",
    "embroidered",
    "sequin",
    "lace",
  ],
  swimwear: [
    "bikini",
    "one-piece",
    "monokini",
    "tankini",
    "triangular",
    "bandeau",
    "halter-neck",
    "push-up",
    "strapless",
    "swim dress",
    "beach cover-up",
    "boardshorts",
    "swim trunk",
    "wetsuit",
    "coverup",
    "swimsuit",
    "skirt",
    "knotted",
  ],
  suit: ["blazer & trouser", "blazer", "cape blazer", "overcoat"],
  dupatta: [
    "lehariya",
    "checked",
    "gotta",
    "ikkat",
    "handloom",
    "bandhani",
    "gadwal",
    "patola",
    "kota",
    "chanderi",
    "mangalagiri",
    "tussar",
    "phulkari",
  ],
  leggings: [
    "regular",
    "high-rise",
    "low-rise",
    "tights",
    "yoga pants",
    "activewear",
    "compression",
    "seamless",
  ],
  shirt: ["checked", "striped", "printed"],
  "salwar-suit": ["anarkali", "churidar", "kashmiri", "patiala"],
  palazzo: [
    "brocade",
    "crochet",
    "embroidered",
    "floral",
    "glitter",
    "lace",
    "metallic",
    "printed",
    "striped",
    "tie-dye",
  ],
  pant: [
    "lace",
    "japanese",
    "pajama",
    "dhoti",
    "potali",
    "pintuck",
    "tulip",
    "straight",
    "slim fit",
    "pleated",
    "striped",
    "harem",
    "culotte",
    "cigarette",
    "chinos",
    "cargo",
    "bootcut",
    "bell bottom",
    "stride",
  ],
  tunic: [
    "straight",
    "a-line",
    "anarkali",
    "flared",
    "kalidar",
    "asymmetric",
    "lehariya",
    "asymmetrical",
    "pintucked",
  ],
  blouse: [
    "padded",
    "with sleeves",
    "without sleeves",
    "sleeveless",
    "stretch",
    "chelsea collar",
    "corset",
    "v neck blouse",
    "wrap blouse",
    "button blouse",
    "shirt blouse",
    "mirror work",
    "heart plunge",
    "elastic loop",
    "chevron",
    "choli cutwork",
    "cap sleeve",
    "back eye",
    "angrakha",
    "dolman",
  ],
  pavada: ["pochampally", "kanchipattu", "kanchipuram", "kalamkari"],
  nightSuit: ["pajamas", "nighty", "nightdress", "nightgown"],
  vest: ["padded", "unpadded", "with sleeves", "without sleeves"],
  kimono: ["printed", "embroidered", "sequin", "lace", "solid-color"],
  cardigan: [
    "cable knit",
    "open front",
    "shawl collar",
    "v-neck",
    "wool",
    "cashmere",
    "alpaca",
  ],
  shawl: [
    "pashmina",
    "jamawar",
    "kani",
    "kashmiri",
    "kullu",
    "amritsari",
    "chikankari",
    "kalamkari",
    "kutch",
    "phulkari",
    "bandhani",
    "banarasi",
    "chanderi",
    "patola",
    "kota",
    "kani",
  ],
  muffler: ["cashmere"],
  hoodie: [
    "zip-up",
    "pullover",
    "graphic",
    "college",
    "fleece",
    "sherpa",
    "oversized",
    "university",
  ],
  sweatshirt: [
    "zip-up",
    "pullover",
    "graphic",
    "college",
    "oversized",
    "university",
  ],
  "body-suit": [
    "strapless",
    "tank top",
    "long sleeve",
    "v-neck",
    "scoop neck",
    "thong",
    "leotard",
    "shapewear",
  ],
  gown: [
    "a-line",
    "ball",
    "cocktail",
    "empire",
    "evening",
    "fit-and-flare",
    "mermaid",
    "sheath",
    "tea-length",
    "wedding",
  ],
  kaftan: [
    "a-line",
    "belted",
    "bohemian",
    "cap-sleeve",
    "casual",
    "colorful",
    "cropped",
    "draped",
    "evening",
    "long",
    "maxi",
    "off-the-shoulder",
    "one-shoulder",
    "printed",
    "short",
    "summer",
    "three-quarter-sleeve",
    "tunic",
    "patola",
  ],
  "co-ord": [
    "athleisure",
    "business casual",
    "casual",
    "evening",
    "festival",
    "formal",
    "hawaiian",
    "loungewear",
    "party",
    "streetwear",
    "vacation",
    "bustier",
  ],
  blazer: [
    "a-line",
    "belted",
    "boyfriend",
    "casual",
    "cropped",
    "double-breasted",
    "fitted",
    "oversized",
    "quilted",

    "single-breasted",
    "tailored",
    "tuxedo",
  ],
  jumpsuit: [
    "a-line",
    "belted",
    "boyfriend",
    "casual",
    "cargo",
    "cropped",
    "floral",
    "halter",
    "maxi",
    "off-the-shoulder",
    "one-shoulder",
    "overall",
    "printed",
    "short",
    "slit",
    "sweatsuit",
    "swing",
    "tuxedo",
  ],
  coat: [
    "ankle-length",
    "belted",
    "boyfriend",
    "casual",
    "chubby",
    "cropped",
    "double-breasted",
    "faux fur",
    "hooded",
    "long",
    "maxi",
    "overcoat",
    "peacoat",
    "quilted",
    "short",
    "trench",
    "wool",
  ],
  shrug: [
    "bolero",
    "cardigan",
    "cropped",
    "hooded",
    "long",
    "maxi",
    "open",
    "short",
    "sweater",
    "three-quarter-sleeve",
  ],
  skirt: [
    "a-line",
    "circle",
    "cocktail",
    "dirndl",
    "empire",
    "fit-and-flare",
    "full",
    "mini",
    "midi",
    "pencil",
    "pleated",
    "pouf",
    "raglan",
    "ruffled",
    "skort",
    "swing",
    "tiered",
    "wrap",
  ],
  capri: [
    "basic",
    "cargo",
    "cuffed",
    "fleece",
    "jogger",
    "legging",
    "lined",
    "mesh",
    "printed",
    "skinny",
    "straight",
    "twill",
    "unlined",
  ],
  trouser: [
    "cargo",
    "chino",
    "corduroy",
    "jogger",
    "khaki",
    "legging",
    "skinny",
    "straight",
    "sweatpant",
  ],
  jacket: [
    "bomber",
    "hoodie",
    "parka",
    "puffer",
    "quilted",
    "trench",
    "windbreaker",
    "shacket",
    "long",
    "patola",
  ],
  choli: [
    "ruched",
    "cropped",
    "halter",
    "off-shoulder",
    "sleeveless",
    "tank top",
  ],
  stole: ["infinity", "pashmina", "cowl", "triangle"],
  combo: [
    "skirt and top",
    "lehenga and choli",
    "saree and blouse",
    "dress and jacket",
    "dress and shrug",
    "dress and belt",
    "dress and scarf",
    "dress and stole",
    "dress and shawl",
    "dress and cardigan",
    "dress and blazer",
    "dress and kimono",
    "dress and cape",
    "dress and poncho",
    "dress and dupatta",
    "dress and coat",
    "dhoti with cape",
    "kurta and palazzo",
    "top and palazzo",
    "kurta and pant",
  ],
  sharara: [
    "angrakha",
    "anarkali",
    "churidar",
    "patiala",
    "straight",
    "a-line",
    "flared",
    "kalidar",
  ],
  gharara: [
    "angrakha",
    "anarkali",
    "churidar",
    "patiala",
    "straight",
    "a-line",
    "flared",
    "kalidar",
  ],
  fabric: [],
};
