export const ACCESSORIES_CATEGORIES = {
  sunglass: [
    "aviator",
    "wayfarer",
    "round",
    "cat eye",
    "butterfly",
    "full rim",
    "rimless",
    "half rim",
  ],
  shoes: ["sneaker", "boot", "loafer", "oxford", "derby", "mule", "wedge"],
  sandal: ["sandal", "jutti", "slide", "flats"],
  bag: [
    "bucket",
    "baguette",
    "sling",
    "tote",
    "hobo",
    "moon",
    "satchel",
    "mini",
    "box",
    "bagpack",
    "laptop",
  ],
  belt: [],
  button: [],
  cufflink: ["classic", "novelty", "engraved", "vintage", "sports", "wedding"],
};
