export const CLOTHING_CATEGORIES = {
  jeans: [
    "skinny",
    "bootcut",
    "straight",
    "flared",
    "high-waist",
    "low-waist",
    "ripped",
    "distressed",
    "acid-washed",
    "wide-leg",
  ],
  suit: ["blazer & trouser", "blazer", "cape blazer", "overcoat"],
  shirt: ["checked", "striped", "printed", "casual"],
  "t-shirt": [
    "polo",
    "polo shirt",
    "round neck",
    "v neck",
    "crew neck",
    "scoop neck",
    "long sleeve",
    "graphic",
    "sports",
    "oversized",
    "zipper",
    "henley",
    "muscle",
    "sleeveless",
    "half sleeve",
    "full sleeve",
    "short sleeve",
  ],
  pant: [
    "japanese",
    "trouser",
    "potali",
    "pintuck",
    "tulip",
    "straight",
    "slim fit",
    "pleated",
    "striped",
    "harem",
    "easy",
    "chino",
    "cargo",
  ],
  pyjama: ["pants", "pajama", "lounge"],
  dhoti: [],
  joggers: ["denim", "pajama", "sweatpant", "trackpant", "casual"],
  shorts: [
    "cargo",
    "chino",
    "board",
    "bermuda",
    "athletic",
    "swim",
    "sweat",
    "track",
    "cutoff",
    "running",
    "basketball",
    "gym",
    "skate",
    "cargo board",
    "hybrid",
    "hiking",
    "beach",
    "printed",
    "patterned",
    "tailored",
    "pleated",
    "drawstring",
    "ripstop",
    "camouflage",
    "vintage",
    "cargo swim",
    "camo cargo",
    "stretch",
    "surf",
    "workout",
    "tennis",
    "golf",
    "seersucker",
    "yoga",
    "basketball",
    "gym",
  ],
  hoodie: [
    "zip-up",
    "pullover",
    "graphic",
    "college",
    "fleece",
    "sherpa",
    "oversized",
    "university",
  ],
  sweatshirt: [
    "zip-up",
    "pullover",
    "graphic",
    "college",
    "oversized",
    "university",
  ],
  kurta: [
    "straight cut",
    "angrakha",
    "pathani",
    "sherwani",
    "bundi",
    "jodhpuri",
    "achkan style",
    "printed",
    "embroidered",
    "collarless",
    "mandarin collar",
    "short",
    "long",
    "asymmetric",
    "indo-western",
    "casual",
    "formal",
    "party wear",
    "wedding",
    "digital print",
    "block print",
    "bandhgala",
    "high-low",
    "pathani style",
    "peshawari",
    "floral print",
    "jacquard",
    "paneled",
    "festive",
    "plain",
    "pintuck",
    "layered",
    "pakistani",
    "punjabi",
    "kalamkari",
    "zari work",
    "mirror work",
    "phulkari",
    "hoodie",
    "ganganagri",
    "chikankari kurta",
    "bandhani kurta",
    "ikat kurta",
    "kalamkari kurta",
    "digital print kurta",
    "block print kurta",
    "embroidered kurta",
    "printed kurta",
  ],
  "co-ord": [
    "athleisure",
    "business casual",
    "casual",
    "evening",
    "festival",
    "formal",
    "hawaiian",
    "loungewear",
    "party",
    "streetwear",
    "vacation",
    "bustier",
  ],
  jacket: [
    "bomber",
    "denim",
    "hoodie",
    "parka",
    "puffer",
    "quilted",
    "trench",
    "windbreaker",
    "shacket",
    "long",
    "patola",
    "overshirt",
  ],
  shawl: [
    "pashmina",
    "jamawar",
    "kani",
    "kashmiri",
    "kullu",
    "amritsari",
    "chikankari",
    "kalamkari",
    "kutch",
    "phulkari",
    "bandhani",
    "banarasi",
    "chanderi",
    "patola",
    "kota",
    "kani",
  ],
  muffler: ["cashmere"],
};
