import * as R from "remeda";
import { objectEntries, objectKeys } from "ts-extras";
import type { PVCM } from "../../nlp-types";
import type { StoreCatalog } from "../../schema";
import { ACCESSORIES_CATEGORIES } from "./accessories";
import { CLOTHING_CATEGORIES } from "./clothing";
import { JEWELLERY_CATEGORIES } from "./jewellery";
export * from "./accessories";
export * from "./clothing";
export * from "./jewellery";

export const getAll = (catalog?: StoreCatalog) => {
  const audience: keyof StoreCatalog = "adult_female";
  // pvcm : PRODUCT_VERTICAL_CATEGORY_MAPPING
  const pvcm: PVCM = {
    clothing: CLOTHING_CATEGORIES,
    jewellery: JEWELLERY_CATEGORIES,
    accessories: ACCESSORIES_CATEGORIES,
    home_decor: {},
    stationary: {},
  };

  const defaultCatalog = {
    [audience]: R.reduce(
      objectEntries(pvcm),
      (acc, [vertical, obj]) => {
        if (acc && objectKeys(obj).length > 0) {
          if (vertical !== "home_decor" && vertical !== "stationary") {
            acc[vertical] = objectKeys(obj);
          }
        }
        return acc;
      },
      {} as StoreCatalog["adult_female"]
    ),
  };

  const obj = catalog
    ? catalog[audience] ?? defaultCatalog[audience]
    : defaultCatalog[audience];

  if (obj) {
    objectEntries(obj).forEach(([vertical, categories]) => {
      if (!pvcm[vertical]) {
        pvcm[vertical] = {};
      } else {
        if (categories) {
          pvcm[vertical] = R.pick(pvcm[vertical], categories);
        }
      }
    });
  }

  const ALL_POSSIBLE_CATEGORIES = objectKeys(pvcm).flatMap((key) =>
    objectKeys(pvcm[key]).flat()
  );

  const VERTICAL_WORDS_MAPPING = objectKeys(pvcm).reduce(
    (acc, key) => {
      acc[key] = R.unique([
        ...Object.values(pvcm[key]).flat(),
        ...Object.keys(pvcm[key]).flat(),
      ]);
      return acc;
    },
    {} as Record<keyof typeof pvcm, string[]>
  );

  const DICTIONARY = R.unique([
    ...Object.keys(pvcm),
    ...Object.values(VERTICAL_WORDS_MAPPING).flat(),
    ...Object.keys(VERTICAL_WORDS_MAPPING).flat(),
  ]);

  return {
    defaultCatalog,
    pvcm: pvcm,
    ALL_POSSIBLE_CATEGORIES,
    VERTICAL_WORDS_MAPPING,
    DICTIONARY,
  };
};
